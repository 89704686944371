<template>
  <div class="layout-header-aside-group">
    <!-- 顶栏 -->
    <div class="theme-header" flex-box="0" flex>
      <div class="header-left">
        <div class="logo-group">
          <img class="logo-img" src="@/assets/image/logo.png" alt="" />
        </div>
        <div class="system-title">管理后台</div>
      </div>
      <div class="header-right">
        <d2-header-user />
      </div>
    </div>
    <!-- 下面 主体 -->
    <div class="d2-theme-container" flex-box="1" flex>
      <!-- 主体 侧边栏 -->
      <div
        ref="aside"
        flex-box="0"
        class="d2-theme-container-aside"
        :style="{
          width: asideCollapse ? asideWidthCollapse : asideWidth,
        }"
      >
        <d2-menu-side />
      </div>
      <!-- 主体 -->
      <div class="d2-theme-container-main" flex-box="1" flex>
        <!-- 内容 -->
        <transition name="fade-scale">
          <div class="d2-theme-container-main-layer" flex="dir:top">
            <!-- tab -->
            <div class="d2-theme-container-main-header main-header" flex-box="0">
              <d2-tabs />
            </div>
            <!-- 页面 -->
            <div
              v-if="isRouterAlive"
              class="d2-theme-container-main-body main-body"
              flex-box="1"
              :style="{ height: bodyHeight }"
            >
              <keep-alive>
                <router-view v-if="$route.meta.cache" :key="$route.meta.key"></router-view>
              </keep-alive>
              <router-view v-if="!$route.meta.cache" :key="$route.meta.key"></router-view>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import d2MenuSide from './components/menu-side';
import d2Tabs from './components/tabs';
import d2HeaderUser from './components/header-user';
import { mapState } from 'vuex';
export default {
  name: 'D2LayoutHeaderAside',
  components: {
    d2MenuSide,
    d2Tabs,
    d2HeaderUser,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '200px',
      isRouterAlive: true,
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: '65px',
    };
  },
  computed: {
    ...mapState('d2admin', {
      keepAlive: state => state.page.keepAlive,
      transitionActive: state => state.transition.active,
      asideCollapse: state => state.menu.asideCollapse,
    }),
    bodyHeight() {
      return document.body.clientHeight - 350;
    },
  },
  methods: {
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside() {
      this.asideCollapseToggle();
    },
    // 刷新页面
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="scss">
// 注册主题
// @import '~@/assets/style/theme/register.scss';

.main-body {
  overflow-x: hidden;
}

// .main-header,
// .d2-theme-header,
// .d2-theme-container-aside {
//   z-index: 1900;
//   background: aliceblue;
// }
</style>
