import Vue from 'vue';
import Vuex from 'vuex';
import d2admin from './modules/d2admin';

// 所有的数据持久化，都必须走vuex，尽量不要直接操作storage！！！！！！！！！

const files = require.context('./modules', false, /\.js$/);
const modules = {};

files.keys().forEach(key => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    d2admin,
    ...modules,
  },
});
