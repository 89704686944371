import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/style/iconfont/iconfont.css';
import './assets/style/index.scss';
import './assets/font/font.css';

// 图片拖拽排序使用的插件
import VueDND from 'zero-dnd';

// 注册全局指令
import './directive';

import api from '@/server/apiSite/index'; // 接口地址
import http from '@/server/request.js'; // 请求 axios

Vue.prototype.api = api; // 挂载到Vue实例上面
Vue.prototype.http = http; // 挂载到Vue实例上面

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(Antd);
Vue.use(VueDND);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
