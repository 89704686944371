import cookies from './util.cookies';
import db from './util.db';
import store from '@/store';
// import log from './util.log'
// import browser from './util.browser'
// import dataType from './util.type'

import { loginByToken } from '@/api/login';

// const { VUE_APP_PUBLIC_PATH } = process.env
import { SINGLE_TOKEN_KEY, SINGLE_UUID_KEY, TOKEN_KEY } from '@/maps/common';

const util = {
  cookies,
  db,
  // log,
  // browser,
  // dataType
};

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = function (titleText) {
  const processTitle = process.env.VUE_APP_TITLE || '';
  window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`;
};

/**
 * @description 获取当前浏览器存储的token
 */
util.getToken = async () => {
  const token = util.cookies.get(TOKEN_KEY);
  return token;
};

export default util;
