// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

export default [
  {
    path: '/documents',
    name: 'documents',
    component: _import('portal/documents/index'),
    meta: { title: '文档中心', rank: 10, auth: false, menu: true, icon: 'iconfont icon-media-home' },
  },
  {
    path: '/billing',
    name: 'billing',
    component: _import('portal/documents/billing'),
    meta: { title: '计费规则', rank: 10, auth: false, menu: true, icon: 'iconfont icon-media-home' },
  },
  {
    path: '/documentsDetail',
    name: 'documentsDetail',
    component: _import('portal/documents/detail'),
    meta: { title: '文档详情', rank: 10, auth: false, menu: true, icon: 'iconfont icon-media-home' },
  },
];
