import Layout from '@/layout/Layout.vue';
import LayoutAdmin from '@/layout-admin/Layout.vue';

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

const files = require.context('./modules', false, /\.js$/);
const modules = []; // 存储各模块的路由信息
export let menuList = []; // 用来显示在左侧的菜单列表

files.keys().forEach(key => {
  // 根据模块设置每个路由归属的菜单模块
  if (files(key).default && files(key).default.length > 0) {
    const defaultMenu = files(key).default.filter(route => route.meta.menu)[0];
    menuList.push(defaultMenu);
    files(key).default.forEach(route => {
      const { meta } = route;
      if (!meta.menu) {
        // 用来记录每个非菜单路由所归属的路由，方便进入非菜单路由页面时左侧菜单能回显出其菜单信息
        meta.belong = defaultMenu.path;
      }
      // 将所有路由加到modules
      modules.push(route);
    });
  }
});

const filesAdmin = require.context('./modules-admin', false, /\.js$/);
const modulesAdmin = []; // 存储各模块的路由信息
export const menuListAdmin = []; // 用来显示在左侧的菜单列表

filesAdmin.keys().forEach(key => {
  // 根据模块设置每个路由归属的菜单模块
  if (filesAdmin(key).default && filesAdmin(key).default.length > 0) {
    const defaultMenu = filesAdmin(key).default.filter(route => route.meta.menu)[0];
    menuListAdmin.push(defaultMenu);
    filesAdmin(key).default.forEach(route => {
      const { meta } = route;
      if (!meta.menu) {
        // 用来记录每个非菜单路由所归属的路由，方便进入非菜单路由页面时左侧菜单能回显出其菜单信息
        meta.belong = defaultMenu.path;
      }
      // 将所有路由加到modules
      modulesAdmin.push(route);
    });
  }
});

// 将菜单按rank排序
menuList = menuList.sort((a, b) => {
  return a.meta.rank - b.meta.rank;
});

/**
 * 在主框架之外显示
 */

const page = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: _import('login/index'),
  //   meta: {
  //     auth: false,
  //     title: '登录',
  //   },
  // },
  // {
  //   path: '/menu',
  //   name: 'menu',
  //   component: _import('menu/index'),
  //   meta: {
  //     auth: false,
  //     title: '菜单',
  //   },
  // },
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: Layout,
    meta: {
      title: '主页',
    },
    children: modules,
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/index',
    component: LayoutAdmin,
    meta: {
      auth: true,
      title: '管理后台',
    },
    children: modulesAdmin,
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    component: _import('portal/login/index'),
    meta: {
      auth: false,
      title: '登录',
    },
  },
];

// 重新组织后导出
export default [...page];
