<template>
  <div class="header" :class="{ 'header-active': isNavShow }">
    <div class="header-left">
      <div class="logo-box" @click="$router.push('/')">
        <img class="logo-img" src="@/assets/image/logo.png" alt="" />
        <!-- <span class="logo-text">多云</span> -->
      </div>
      <mg-nav class="nav-list-box" @visible-change="handleNavShow" />
    </div>
    <div class="header-right">
      <el-dropdown v-if="username" size="small" class="d2-mr" placement="bottom">
        <div class="userinfo">
          <i class="el-icon-user"></i>
          <span class="username">{{ username }}</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click.native="logOff"> 退出登录 </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <div v-else class="console" @click="goAdmin">
        <span>登录/注册</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MgNav from './MgNav';
import { getUsername, removeAuthData } from '@/libs/util.auth.js';
// import util from '@/libs/util'
// import { logout } from '@/api/login';
// import { TOKEN_KEY } from '@/maps/common';
export default {
  name: 'LayoutHeader',
  components: {
    MgNav,
  },
  data() {
    return {
      isNavShow: false,
      username: null,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    // username() {
    //   return this.userInfo.displayname || this.userInfo.name;
    // },
    // ...mapState('user', ['userInfo']),
  },
  async created() {
    // await this.$store.dispatch('auth/getInfo')
    this.username = getUsername();
  },
  methods: {
    handleNavShow(isShow) {
      this.isNavShow = isShow;
    },

    goAdmin() {
      const url = process.env.VUE_APP_MULTICLOUD;
      window.open(url, '_blank');
    },

    // 退出登录
    logOff() {
      removeAuthData();
      this.username = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.333vw;
  padding: 0 8.333vw;

  &.header-active {
    box-shadow: 0 1px 0.417vw 0 rgba(0, 0, 0, 0.08);
  }

  .header-left {
    display: flex;
    align-items: center;

    .logo-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .logo-img {
        width: 146px;
        height: 48px;
      }

      .logo-text {
        height: 1.042vw;
        color: #3d3d3d;
        font-weight: 500;
        font-size: 1.042vw;
        font-family: 'AlibabaPuHuiTi2', sans-serif;
        line-height: 1.042vw;
        opacity: 1;
      }
    }

    .nav-list-box {
      margin-left: 1.042vw;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .login-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 3.75vw;
      height: 2.188vw;
      margin-left: 3.125vw;
      padding: 1.042vw;
      color: #fff;
      font-weight: 500;
      font-size: 0.833vw;
      line-height: 1.25vw;
      background: #0066fe;
    }

    .userinfo {
      margin-left: 1.042vw;
      color: #646566;
      cursor: pointer;

      .username {
        margin-left: 10px;
      }
    }

    .console {
      color: #646566;
      cursor: pointer;
    }
  }
}
</style>
