<template>
  <div class="mg-support">
    <div class="main-box">
      <div class="left">
        <div class="support-box">
          <img src="@/assets/image/layout/support-img.jpg" alt="" class="img" />
          <div class="title">7×24小时专业服务</div>
          <!-- <div class="title">持续陪伴上云旅程</div> -->
          <div class="desc">
            提供多样化的支持计划和专家服务，满足上云咨询、迁移上云、云上运维等场景的全链路服务需求。
          </div>
          <!-- <div class="more">了解更多</div> -->
        </div>
      </div>
      <div class="right">
        <support-menu title="服务与支持" :menus="menus" @menu-click="handleMenuClick" />
      </div>
    </div>
  </div>
</template>

<script>
import SupportMenu from '@/components/Menu';
import { supportMenus } from '@/maps/common';

export default {
  name: 'MgSupport',
  components: {
    SupportMenu,
  },
  data() {
    return {
      menus: supportMenus,
    };
  },
  methods: {
    handleMenuClick(item) {
      this.$router.push(item.url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixin.scss';

.mg-support {
  height: 100%;
  padding: 0 10.417vw;

  .main-box {
    display: flex;
    width: 100%;
    height: 100%;

    .left {
      box-sizing: border-box;
      width: 18.229vw;
      height: 100%;
      border-right: 1px solid #d8d8d8;

      .support-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding-right: 3.125vw;

        .img {
          width: 14.688vw;
          height: 8.021vw;
          margin-top: 2.083vw;
        }

        .title {
          margin-top: 0.833vw;
          color: #222;
          font-weight: normal;
          font-size: 0.833vw;
          line-height: 1.25vw;
        }

        .desc {
          width: 14.688vw;
          height: 2.396vw;
          margin-top: 0.417vw;
          color: #999;
          font-weight: normal;
          font-size: 0.729vw;
          line-height: 1.146vw;
          letter-spacing: 0;
          white-space: normal;
          @include ellipsis(2);
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 3.333vw;
          height: 1.354vw;
          margin-top: 1.042vw;
          color: #646566;
          font-weight: normal;
          font-size: 0.625vw;
          line-height: 0.938vw;
          letter-spacing: 0;
          border: 1px solid #999;
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    .right {
      flex: 1;
      height: 100%;
    }
  }
}
</style>
