import user from './modules/user';
import menu from './modules/menu';

const { NODE_ENV, VUE_APP_SERVER } = process.env;

const api = {
  prefix: NODE_ENV === 'development' ? '' : `${VUE_APP_SERVER}`, // 线上接口前缀
  // prefix: `${VUE_APP_SERVER}`, // 线上接口前缀
  user,
  menu,
};
export default api;
