import request from '@/server/request';

// 菜单列表
export function getMenulist(params) {
  return request({
    url: '/portal/product/config/menuInfo',
    method: 'GET',
    params,
  });
}

// 根据菜单id获取组件详情
export function getCfgInfo(params) {
  return request({
    url: '/portal/product/config/cfgInfo',
    method: 'GET',
    params,
  });
}

// 修改组件信息
export function updateCfgInfo(data) {
  return request({
    url: '/portal/product/config/updateInfo',
    method: 'post',
    data,
  });
}
