// 菜单
const { VUE_APP_API_MODULE } = process.env;
const symbol = VUE_APP_API_MODULE;
module.exports = {
  // 菜单列表
  list: `${symbol}/api/menu/list`,
  // 根据菜单id获取组件详情
  getCfgInfo: `${symbol}/api/menu/getCfgInfo`,
  // 修改组件信息
  updateCfgInfo: `${symbol}/api/menu/updateCfgInfo`,
};
