// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

export default [
  {
    path: '/admin/index',
    name: 'adminIndex',
    component: _import('admin/welcome/index'),
    meta: { title: '欢迎', rank: 10, auth: true, menu: true, icon: 'iconfont icon-media-home' },
  },
  {
    path: '/admin/bidding-mng',
    name: 'BiddingMng',
    component: _import('admin/bidding-mng/index'),
    meta: {
      title: '公告配置',
      rank: 10,
      auth: true,
      menu: true,
      icon: 'iconfont icon-media-home',
    },
  },
  {
    path: '/admin/product-mng',
    name: 'ProductMng',
    component: _import('admin/product-mng/index'),
    meta: {
      title: '产品配置',
      rank: 10,
      auth: true,
      menu: true,
      icon: 'iconfont icon-media-home',
    },
  },
];
