export default {
  // 侧边栏默认折叠状态
  menu: {
    asideCollapse: false,
  },
  // 在读取持久化数据失败时默认页面
  page: {
    opened: [
      {
        name: 'adminIndex',
        fullPath: 'admin/index',
        meta: {
          title: '首页',
          auth: false,
        },
      },
    ],
  },
  // 是否默认开启页面切换动画
  transition: {
    active: true,
  },
};
