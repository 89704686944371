<template>
  <el-menu-item :index="menu.path || uniqueId">
    <!-- <i v-if="menu.icon" :class="`fa fa-${menu.icon}`"></i> -->
    <i v-if="menu.icon" :class="menu.icon"></i>
    <template #title>
      <span>{{ menu.title || '未命名菜单' }}</span>
    </template>
  </el-menu-item>
</template>

<script>
import { uniqueId } from 'lodash';
export default {
  name: 'D2LayoutHeaderAsideMenuItem',
  props: {
    menu: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      uniqueId: uniqueId('d2-menu-empty-'),
    };
  },
};
</script>
