<template>
  <el-dropdown size="small" class="d2-mr">
    <span class="btn-text">{{ userInfo && userInfo.userName ? `你好 ${userInfo.userName}` : '未登录' }}</span>
    <template #dropdown>
      <el-dropdown-menu>
        <!-- <el-dropdown-item @click.native="editPassword">
          <i class="el-icon-edit" />
          修改密码
        </el-dropdown-item> -->
        <el-dropdown-item @click.native="logOff">
          <!-- <d2-icon name="power-off" class="d2-mr-5" /> -->
          退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      userName: '',
    };
  },
  computed: {
    ...mapState('d2admin/user', ['userInfo']),
  },
  methods: {
    ...mapActions('d2admin/user', ['logout']),
    /**
     * @description 登出
     */
    logOff() {
      this.logout().then(() => {
        this.$router.push('/admin/login');
      });
    },
  },
};
</script>
