// ========== 账号相关 ==========
import { Base64 } from 'js-base64';
const UsernameKey = 'USERNAME';
const PasswordKey = 'PASSWORD';
const RememberMeKey = 'REMEMBER_ME';
const authDataKey = 'authData';

export function getUsername() {
  let authData = localStorage.getItem(authDataKey);
  try {
    authData = JSON.parse(authData);
  } catch (error) {}
  let username = '';
  if (authData && authData.user && authData.user.username) {
    username = authData.user.username;
  }
  return username;
}

export function setUsername(username) {
  localStorage.setItem(UsernameKey, username);
}

export function removeUsername() {
  localStorage.removeItem(UsernameKey);
}

export function removeAuthData() {
  localStorage.removeItem(authDataKey);
}

export function getPassword() {
  const password = localStorage.getItem(PasswordKey);
  return password ? Base64.decode(password) : undefined;
}

export function setPassword(password) {
  localStorage.setItem(PasswordKey, Base64.encode(password));
}

export function removePassword() {
  localStorage.removeItem(PasswordKey);
}

export function getRememberMe() {
  return localStorage.getItem(RememberMeKey) === 'true';
}

export function setRememberMe(rememberMe) {
  localStorage.setItem(RememberMeKey, rememberMe);
}

export function removeRememberMe() {
  localStorage.removeItem(RememberMeKey);
}
