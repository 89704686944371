import Vue from 'vue';
import VueRouter from 'vue-router';
// import api from '../server/apiSite'
// import axios from 'axios'
import store from '@/store/index';
// 进度条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import util from '@/libs/util';
// 路由数据
import routes from './routes';

// fix vue-router NavigationDuplicated
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(err => err);
};
const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  const { meta: toMeta = {}, path: toPath } = to;
  const { auth = true } = toMeta;
  // 进度条
  NProgress.start();
  console.log(1);
  if (toPath.indexOf('/admin') !== -1) {
    const token = await util.getToken();
    if (token) {
      if (to.path === '/admin/login') {
        next('/admin');
      }
      next();
    } else {
      // 需要登录则跳转到登录页
      if (auth) {
        router.push({
          path: '/admin/login',
          query: {
            redirect: encodeURIComponent(toPath),
          },
        });
      }
      next();
      NProgress.done();
    }
  } else {
    next();
  }
  // const token = await util.getToken();
  // if (token) {
  //   if (to.path === '/login') {
  //     next('/');
  //   } else {

  //   }
  //   // next();
  // } else {
  //   // 需要登录则跳转到登录页
  //   if (auth) {
  //     router.push({
  //       path: '/login',
  //       query: {
  //         redirect: encodeURIComponent(toPath),
  //       },
  //     });
  //   }
  //   next();
  //   NProgress.done();
  // }
});

router.afterEach(to => {
  // 进度条
  NProgress.done();
  // 多页控制 打开新的页面
  store.dispatch('d2admin/page/open', to);
  // 更改标题
  util.title(to.meta.title);
  // 置顶
  window.scrollTo(0, 0);
});

export default router;
