// 设置文件
import setting from '@/setting.js';

export default {
  namespaced: true,
  state: {
    // 顶栏菜单
    header: [],
    // 侧栏菜单
    aside: [
      {
        path: '/admin',
        title: 'portal管理',
        children: [
          {
            path: '/admin/bidding-mng',
            icon: 'el-icon-eleme',
            title: '公告配置',
          },
          {
            path: '/admin/product-mng',
            icon: 'el-icon-eleme',
            title: '产品配置',
          },
        ],
      },
    ],
    // 侧边栏收缩
    asideCollapse: setting.menu.asideCollapse,
  },
  actions: {
    /**
     * 设置侧边栏展开或者收缩
     * @param {Object} state vuex state
     * @param {Boolean} collapse is collapse
     */
    async asideCollapseSet({ state, dispatch }, collapse) {
      // store 赋值
      state.asideCollapse = collapse;
      // 持久化
      await dispatch(
        'd2admin/db/set',
        {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          value: state.asideCollapse,
          user: true,
        },
        { root: true },
      );
      // end
    },
    /**
     * 切换侧边栏展开和收缩
     * @param {Object} state vuex state
     */
    async asideCollapseToggle({ state, dispatch }) {
      // store 赋值
      state.asideCollapse = !state.asideCollapse;
      // 持久化
      await dispatch(
        'd2admin/db/set',
        {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          value: state.asideCollapse,
          user: true,
        },
        { root: true },
      );
      // end
    },
    /**
     * 从持久化数据读取侧边栏展开或者收缩
     * @param {Object} state vuex state
     */
    async asideCollapseLoad({ state, dispatch }) {
      // store 赋值
      state.asideCollapse = await dispatch(
        'd2admin/db/get',
        {
          dbName: 'sys',
          path: 'menu.asideCollapse',
          defaultValue: setting.menu.asideCollapse,
          user: true,
        },
        { root: true },
      );
      // end
    },
    /**
     * 设置侧边栏数据
     * @param state
     * @param dispatch
     * @param commit
     * @param menu 侧边栏数据
     */
    async asideSet({ dispatch, commit }, menu) {
      commit('asideSet', menu);
      await dispatch(
        'd2admin/db/set',
        {
          dbName: 'sys',
          path: 'menu.list',
          value: menu,
          user: true,
        },
        { root: true },
      );
    },
    async asideLoad({ dispatch, commit }) {
      const menu = await dispatch(
        'd2admin/db/get',
        {
          dbName: 'sys',
          path: 'menu.list',
          defaultValue: [],
          user: true,
        },
        { root: true },
      );
      commit('asideSet', menu);
    },
  },
  mutations: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state vuex state
     * @param {Array} menu menu setting
     */
    headerSet(state, menu) {
      // store 赋值
      state.header = menu;
    },
    /**
     * @description 设置侧边栏菜单
     * @param {Object} state vuex state
     * @param {Array} menu menu setting
     */
    asideSet(state, menu) {
      // store 赋值
      state.aside = menu;
    },
  },
};
