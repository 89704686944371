export default {
  namespaced: true,
  state: {
    title: process.env.VUE_APP_TITLE || '', // 系统名称
    appid: process.env.VUE_APP_ID || '', // 系统id
    isLogin: false, // 是否登录了
  },
  getters: {
    getTitle(state) {
      return state.title;
    },
    getAppID(state) {
      return state.appid;
    },
  },
  mutations: {
    SET_ISLOGIN: (state, data) => {
      state.isLogin = data;
    },
  },
  actions: {
    /**
     * @description 设置系统登录状态
     * @param {Object} state vuex state
     */
    setIsLogin({ commit }, data) {
      commit('SET_ISLOGIN', data);
    },

    /**
     * @description 从持久化数据加载一系列的设置
     * @param {Object} state vuex state
     */
    async load({ dispatch }) {
      // DB -> store 加载用户token
      // await dispatch('user/loadToken', null, { root: true });
      // DB -> store 加载用户info
      await dispatch('d2admin/user/load', null, { root: true });
    },
  },
};
