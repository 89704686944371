// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

export default [
  {
    path: '/bidding',
    name: 'bidding',
    component: _import('portal/bidding/index'),
    meta: { title: '招投标中心', rank: 10, auth: false, menu: true, icon: 'iconfont icon-media-home' },
  },
  {
    path: '/biddingDetail',
    name: 'biddingDetail',
    component: _import('portal/bidding/detail'),
    meta: { title: '招投标详情', rank: 10, auth: false, menu: true, icon: 'iconfont icon-media-home' },
  },
];
