<template>
  <div class="layout-container">
    <div class="layout-content">
      <div class="layout-header">
        <mg-header />
      </div>
      <div class="layout-body">
        <router-view />
      </div>
      <div class="layout-footer">
        <mg-footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MgHeader from './components/MgHeader.vue';
import MgFooter from './components/MgFooter.vue';

export default {
  name: 'Layout',
  components: { MgHeader, MgFooter },
  data() {
    return {
      scale: 1,
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('system', ['title']),
  },
  methods: {
    getScale(data) {
      this.scale = data || 1;
    },
  },
};
</script>

<style lang="scss">
.layout-container {
  display: flex;
  overflow-x: hidden;
  color: #000;

  .layout-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .layout-body {
      width: 100%;
    }
  }
}
</style>
