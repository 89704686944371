import { TOKEN_KEY } from '@/maps/common';
import util from '@/libs/util';
export default {
  namespaced: true,
  state: {
    // 用户信息
    userInfo: {},
  },
  getters: {
    getUserInfo(state) {
      return state.info || {};
    },
  },
  actions: {
    /**
     * 账号密码登录
     * @param {*} param0
     * @param {*} data
     * @returns
     */
    login({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        if (data.userName === 'admin' && data.password === '1234') {
          util.cookies.set(TOKEN_KEY, 'portal-cookie', {
            expires: 1000 * 60 * 60 * 24,
          });
          dispatch('set', {
            userName: data.userName,
          });
          resolve(true);
        }
        reject(new Error(false));
      });
    },
    /**
     * 退出登录
     * @param {*} param0
     * @returns
     */
    logout({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        util.cookies.remove(TOKEN_KEY);
        dispatch('set', null);
        resolve(true);
      });
    },
    /**
     * @description 设置用户数据
     * @param {Object} state vuex state
     * @param {*} info info
     */
    async set({ state, dispatch }, info) {
      // store 赋值
      state.userInfo = info;
      // 持久化
      await dispatch(
        'd2admin/db/set',
        {
          dbName: 'sys',
          path: 'user.userInfo',
          value: info,
          user: true,
        },
        { root: true },
      );
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} state vuex state
     */
    async load({ state, dispatch }) {
      // store 赋值
      state.userInfo = await dispatch(
        'd2admin/db/get',
        {
          dbName: 'sys',
          path: 'user.userInfo',
          defaultValue: {},
          user: true,
        },
        { root: true },
      );
    },
  },
};
