// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV);

export default [
  {
    path: '/products/:id',
    name: 'products',
    component: _import('portal/products/index'),
    meta: { title: '产品详情', rank: 10, auth: false, menu: true, icon: 'iconfont icon-media-home' },
  },
];
